import React, {useState} from "react";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import reales from '../../../assets/images/realise.png';
import soon from '../../../assets/images/soon.png';
import mbGameRu from '../../../assets/images/mb-gameRU.png';
import mbGameEn from '../../../assets/images/mb-gameEN.png';
import vpn from '../../../assets/images/vpn.png'
import {useTranslation} from 'react-i18next';

const images = [
    {reales},
    {reales},
    {reales},
    {reales},
    {reales},
];

const Portfolio = () => {
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const {t, i18n} = useTranslation();

    return (
        <div>
            <section className="ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix" id="projectsSection">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"/>
                <div className="container-xl pb-5 pt-5 res-991-pb-0">
                    <div className="row align-items-center ttm-textcolor-white">
                        <div className="col-lg-12 res-991-text-center">
                            <div className="section-title clearfix">
                                <h2 className="title text-center">
                                    {t('portfolio.mainText.label')}
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ttm-row mt-10 zero-padding-section clearfix">
                <div className="container-fluid p-0">
                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={
                                images[(photoIndex + images.length - 1) % images.length]
                            }
                            onCloseRequest={() => setIsOpen(false)}
                            onMovePrevRequest={() =>
                                setPhotoIndex((photoIndex + images.length - 1) % images.length)
                            }
                            onMoveNextRequest={() =>
                                setPhotoIndex((photoIndex + 1) % images.length)
                            }
                        />
                    )}
                    <Slider
                        className="row slick_slider ttm-boxes-spacing-10px"
                        {...slick_slider}
                        slidesToShow={5}
                    >
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img
                                        className="img-fluid"
                                        alt=""
                                        src={vpn}
                                    />
                                </div>
                                {/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="featured-title">
                                        {/* featured-title */}
                                        <h5>
                                            <a href="#">
                                                ELEMENT VPN
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-portfolio */}
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img
                                        className="img-fluid"
                                        alt=""
                                        src={i18n.languages[0] == 'ru' ? mbGameRu : mbGameEn}
                                    />
                                </div>
                                {/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="featured-title">
                                        {/* featured-title */}
                                        <h5>
                                            <a href='#'>
                                                {t('portfolio.releaseSoon.label')}
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* featured-thumbnail */}
                        </div>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-portfolio */}
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img
                                        className="img-fluid"
                                        alt=""
                                        src={i18n.languages[0] == 'ru' ? mbGameRu : mbGameEn}
                                    />
                                </div>
                                {/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="featured-title">
                                        {/* featured-title */}
                                        <h5>
                                            <a>
                                                {t('portfolio.releaseSoon.label')}
                                            </a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/* featured-thumbnail */}
                        </div>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-portfolio */}
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img
                                        className="img-fluid"
                                        alt=""
                                        src={i18n.languages[0] == 'ru' ? reales : soon}
                                    />
                                </div>
                                {/* featured-thumbnail end*/}
                            </div>
                            {/* featured-thumbnail */}
                        </div>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-portfolio */}
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img
                                        className="img-fluid"
                                        alt=""
                                        src={i18n.languages[0] == 'ru' ? reales : soon}
                                    />
                                </div>
                                {/* featured-thumbnail end*/}
                            </div>
                            {/* featured-thumbnail */}
                        </div>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-portfolio */}
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img
                                        className="img-fluid"
                                        alt=""
                                        src={i18n.languages[0] == 'ru' ? reales : soon}
                                    />
                                </div>
                                {/* featured-thumbnail end*/}
                            </div>
                            {/* featured-thumbnail */}
                        </div>
                    </Slider>
                </div>
            </section>
        </div>
    )
}

export default Portfolio;
