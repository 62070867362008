import React from "react";
import {useTranslation} from 'react-i18next';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = () => {
    const {t, i18n} = useTranslation();

    return (
        <nav id="menu" role="navigation" className="menu">
            <ul>
                <li className="menu-hasdropdown">
                    <AnchorLink href='#homeSection'>{t('menu.home.label')}</AnchorLink>
                    {/*<a href="/#homeSection">  </a>*/}
                </li>
                <li className="menu-hasdropdown">
                    <AnchorLink href='#aboutUsSection'>{t('menu.aboutUS.label')}</AnchorLink>
                    {/*<a href="/#aboutUsSection"> {t('menu.aboutUS.label')} </a>*/}
                </li>
                <li className="menu-hasdropdown">
                    <AnchorLink href='#projectsSection'>{t('menu.products.label')}</AnchorLink>
                    {/*<a href="/#projectsSection"> {t('menu.products.label')} </a>*/}
                </li>
                <li className="menu-hasdropdown">
                    <AnchorLink href='#servicesSection'> {t('menu.services.label')} </AnchorLink>
                    {/*<a href="/#servicesSection"> {t('menu.services.label')} </a>*/}
                </li>
                <li className="menu-hasdropdown">
                    <AnchorLink href='#contactSection'> {t('menu.contact.label')}</AnchorLink>
                    {/*<a href="/#contactSection"> {t('menu.contact.label')} </a>*/}
                </li>
            </ul>
        </nav>
    )
}

export default Header;