import Slider from "react-slick";
import React from "react";
import {useTranslation} from "react-i18next";


const Services = () => {
    let slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const {t, i18n} = useTranslation();


    return (
        <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix"
                 id="servicesSection">
            <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"/>
            <div className="container-xl">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        {/* section title */}
                        <div className="section-title with-desc text-center clearfix">
                            <div className="title-header">
                                <h5>  {t('services.mainText.label')} </h5>
                                <h2 className="title">
                                    {t('services.secondMainText.label')}
                                </h2>
                            </div>
                        </div>
                        {/* section title end */}
                    </div>
                </div>
                <Slider
                    className="row slick_slider ttm-boxes-spacing-30px"
                    {...slick_slider}
                >
                    <div className="ttm-box-col-wrapper">
                        <div className="ttm-history-box-wrapper">
                            {/* ttm-history-box-wrapper  */}
                            <div className="ttm-history-box-icon-wrapper">
                                {/* ttm-history-box-icon-wrapper  */}
                                {/*  featured-icon-box */}
                                <div className="featured-icon-box">
                                    <div className="featured-icon">
                                        {/*  featured-icon */}
                                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                                            <i className="flaticon flaticon-business-and-finance"/>
                                            {/*  ttm-icon */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-history-box-border"/>
                            {/* ttm-history-box-border  */}
                            <div className="ttm-history-box-details ttm-bgcolor-white">
                                <div className="ttm-historybox-title">
                                    <h5>  {t('services.phOne.mainText.label')} </h5>
                                </div>
                                {/* historybox-title  */}
                                <div className="ttm-historybox-description">
                                    {/* description  */}
                                    {t('services.phOne.loremText.label')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                        <div className="ttm-history-box-wrapper">
                            {/* ttm-history-box-wrapper  */}
                            <div className="ttm-history-box-icon-wrapper">
                                {/* ttm-history-box-icon-wrapper  */}
                                {/*  featured-icon-box */}
                                <div className="featured-icon-box">
                                    <div className="featured-icon">
                                        {/*  featured-icon */}
                                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                                            <i className="flaticon flaticon-computer"/>
                                            {/*  ttm-icon */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-history-box-border"/>
                            {/* ttm-history-box-border  */}
                            <div className="ttm-history-box-details ttm-bgcolor-white">
                                <div className="ttm-historybox-title">
                                    <h5>  {t('services.phTwo.mainText.label')} </h5>
                                </div>
                                {/* historybox-title  */}
                                <div className="ttm-historybox-description">
                                    {/* description  */}
                                    {t('services.phTwo.loremText.label')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                        <div className="ttm-history-box-wrapper">
                            {/* ttm-history-box-wrapper  */}
                            <div className="ttm-history-box-icon-wrapper">
                                {/* ttm-history-box-icon-wrapper  */}
                                {/*  featured-icon-box */}
                                <div className="featured-icon-box">
                                    <div className="featured-icon">
                                        {/*  featured-icon */}
                                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                                            <i className="flaticon flaticon-data"/>
                                            {/*  ttm-icon */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-history-box-border"/>
                            {/* ttm-history-box-border  */}
                            <div className="ttm-history-box-details ttm-bgcolor-white">
                                <div className="ttm-historybox-title">
                                    <h5>  {t('services.phThree.mainText.label')} </h5>
                                </div>
                                {/* historybox-title  */}
                                <div className="ttm-historybox-description">
                                    {/* description  */}
                                    {t('services.phThree.loremText.label')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ttm-box-col-wrapper">
                        <div className="ttm-history-box-wrapper">
                            {/* ttm-history-box-wrapper  */}
                            <div className="ttm-history-box-icon-wrapper">
                                {/* ttm-history-box-icon-wrapper  */}
                                {/*  featured-icon-box */}
                                <div className="featured-icon-box">
                                    <div className="featured-icon">
                                        {/*  featured-icon */}
                                        <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                                            <i className="flaticon flaticon-global-1"/>
                                            {/*  ttm-icon */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-history-box-border"/>
                            {/* ttm-history-box-border  */}
                            <div className="ttm-history-box-details ttm-bgcolor-white">
                                <div className="ttm-historybox-title">
                                    <h5>  {t('services.phFour.mainText.label')} </h5>
                                </div>
                                {/* historybox-title  */}
                                <div className="ttm-historybox-description">
                                    {/* description  */}
                                    {t('services.phFour.loremText.label')}
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
    )
}

export default Services;
