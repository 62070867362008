import React, {useState} from "react";
import {
    MDBCollapse,
    MDBDropdown,
    MDBDropdownItem,
    MDBHamburgerToggler,
    MDBNavbar,
    MDBNavbarNav,
    MDBNavItem,
} from "mdbreact";
import {BrowserRouter as Router} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AnchorLink from "react-anchor-link-smooth-scroll";


const MobileMenu = () => {
    const [collapse1, setCollapse1] = useState(false);

    const {t, i18n} = useTranslation();

    return (
        <Router>
            <MDBNavbar>
                <MDBHamburgerToggler
                    id="hamburger1"
                    onClick={() => setCollapse1(!collapse1)}
                />
                <MDBCollapse isOpen={collapse1} navbar>
                    <MDBNavbarNav left>
                        <MDBNavItem active>
                            <MDBDropdown>
                                <MDBDropdownItem href="#homeSection">
                                    <span> <AnchorLink href='#homeSection'>{t('menu.home.label')}</AnchorLink> </span>
                                </MDBDropdownItem>
                            </MDBDropdown>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownItem href="#aboutUsSection">
                                    <span> <AnchorLink href='#aboutUsSection'>{t('menu.aboutUS.label')}</AnchorLink> </span>
                                </MDBDropdownItem>
                            </MDBDropdown>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownItem href="#projectsSection">
                                    <span>  <AnchorLink href='#projectsSection'>{t('menu.products.label')}</AnchorLink> </span>
                                </MDBDropdownItem>
                            </MDBDropdown>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownItem href="#servicesSection">
                                    <span>  <AnchorLink href='#servicesSection'> {t('menu.services.label')} </AnchorLink> </span>
                                </MDBDropdownItem>
                            </MDBDropdown>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownItem href="#contactSection">
                                    <span><AnchorLink href='#contactSection'> {t('menu.contact.label')}</AnchorLink> </span>
                                </MDBDropdownItem>
                            </MDBDropdown>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
        </Router>
    );
}

export default MobileMenu;
