import React from 'react'
import {useTranslation} from 'react-i18next'

const LanguageSelector = () => {
    const {t, i18n} = useTranslation()

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
    }

    return (
        <div onChange={changeLanguage}>
            <label style={{margin: "0 10px", cursor: "pointer"}}>
                RU
                <input type="radio" value="ru" name="language" style={{display: "none"}}/>
            </label>
            <label style={{cursor: "pointer"}}>
                EN
                <input type="radio" value="en" name="language" style={{display: "none"}} defaultChecked/>
            </label>
        </div>
    )
}

export default LanguageSelector;