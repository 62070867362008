import React from "react";
import aboutUS from '../../../assets/images/aboutUS.jpg'
import {useTranslation} from 'react-i18next';

const AboutUS = () => {
    const {t, i18n} = useTranslation();

    return (
        <section className="ttm-row aboutus-section clearfix" id="aboutUsSection">
            <div className="container-xl">
                <div className="row">
                    <div className="col-lg-6">
                        {/* section title */}
                        <div className="section-title with-desc clearfix">
                            <div className="title-header">
                                <h5> {t('aboutUS.mainText.label')} </h5>
                                <h2 className="title">
                                    {t('aboutUS.secondMainText.label')}
                                </h2>
                            </div>
                            <div className="title-desc">
                                <p>
                                    {t('aboutUS.loremText.label')}
                                </p>
                                <p>
                                    {t('aboutUS.secondloremText.label')}
                                </p>
                            </div>
                        </div>
                        {/* section title end */}
                        {/* row */}
                        <div className="row no-gutters mt-4">
                            <div className="col-md-6 col-lg-6 col-sm-6">
                                <ul className="ttm-list ttm-list-style-icon">
                                    <li>
                                        <i className="far fa-circle ttm-textcolor-skincolor"/>
                                        <span className="ttm-list-li-content">
                                            {t('aboutUS.phOne.label')}
                                        </span>
                                    </li>
                                    <li>
                                        <i className="far fa-circle ttm-textcolor-skincolor"/>
                                        <span className="ttm-list-li-content">
                                            {t('aboutUS.phTwo.label')}
                                         </span>
                                    </li>
                                    <li>
                                        <i className="far fa-circle ttm-textcolor-skincolor"/>
                                        <span className="ttm-list-li-content">
                                            {t('aboutUS.phThree.label')}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6">
                                <ul className="ttm-list ttm-list-style-icon">
                                    <li>
                                        <i className="far fa-circle ttm-textcolor-skincolor"/>
                                        <span className="ttm-list-li-content">
                                            {t('aboutUS.phFour.label')}
                                        </span>
                                    </li>
                                    <li>
                                        <i className="far fa-circle ttm-textcolor-skincolor"/>
                                        <span className="ttm-list-li-content">
                                            {t('aboutUS.phFive.label')}
                                        </span>
                                    </li>
                                    <li>
                                        <i className="far fa-circle ttm-textcolor-skincolor"/>
                                        <span className="ttm-list-li-content">
                                            {t('aboutUS.phSix.label')}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* row END*/}

                    </div>
                    <div className="col-lg-6 res-991-mt-40 res-991-text-center">
                        <div className="position-relative res-991-mt-30">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper">
                                <img
                                    className="img-fluid"
                                    src={aboutUS}
                                    title="single-img-one"
                                    alt="single-img-one"
                                />
                            </div>
                            {/* ttm_single_image-wrapper end */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUS;
