import React, {Component} from 'react'
import {withRouter} from 'react-router-dom';

class Footer extends Component {
    handleLink = (link) => {
        this.props.history.push(link);
    }

    render() {
        return (
            <footer className="footer widget-footer clearfix">
                {this.props.children}
                <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                    <div className="container-xl">
                        <div className="row copyright align-items-center res-767-text-center">
                            <div className="col-md-6">
                                <div>
                                    <span>Copyright © 2020 <a onClick={this.handleLink.bind(this, '/')}> ELEMENT 63 </a> </span>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-right res-767-mt-10">
                                    <div className="d-lg-inline-flex">
                                        <ul id="menu-footer-menu" className="footer-nav-menu">
                                            <li><a onClick={this.handleLink.bind(this, '/terms')}> Terms &
                                                Conditions </a></li>
                                            <li><a onClick={this.handleLink.bind(this, '/privacy')}> Privacy Policy </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withRouter(Footer);