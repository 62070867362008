import React, {Suspense} from "react";
import {createBrowserHistory} from "history";
import {BrowserRouter as Router, Route} from "react-router-dom";
import '../../i18n'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../layout/Footer";
import Home from "../Pages/Home/Home";
import Terms from "../Pages/Terms";
import Privacy from "../Pages/Privacy";
import ScrollToTop from "../layout/Gotop";
import ScrollTop from "../layout/ScrollTop";

const appHistory = {
    basename: process.env.PUBLIC_URL,
};
const history = createBrowserHistory(appHistory);

function App() {
    return (
        <div className="page">
            <Router basename={process.env.PUBLIC_URL} history={history}>
                <ScrollTop>
                    <Suspense fallback={null}>
                        <div id="preloader">
                            <div id="status">&nbsp;</div>
                        </div>
                        <ScrollToTop/>
                        <Route exact path="/" component={Home}/>
                        <Route path="/terms" component={Terms}/>
                        <Route path="/privacy" component={Privacy}/>
                        <Footer/>
                    </Suspense>
                </ScrollTop>
            </Router>
        </div>
    );
}

export default App;
