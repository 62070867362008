import React from "react";
import Menu from "./menu";
import MobileMenu from "./Mobile_menu";
import {useTranslation} from 'react-i18next';
import LanguageSelector from "./SelectorLanguage";

const Header = () => {
    const {t, i18n} = useTranslation();

    return (
        <header id="masthead" className="header ttm-header-style-01">
            {/* ttm-topbar-wrapper */}
            <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-7">
                            <ul className="top-contact text-left">
                                <li>
                                    <i className="fas fa-map-marker-alt"/>
                                    &nbsp;&nbsp;
                                    {t('officeHeader.label')}
                                </li>
                                <li>
                                    <i className="far fa-envelope"/>
                                    &nbsp;&nbsp;
                                    <a to="mailto:contact@element63.com">contact@element63.app</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-5">
                            <div className="topbar-right text-right d-flex justify-content-end">
                                <ul className="top-contact">
                                    <li>
                                        <i className="far fa-clock"/>
                                        &nbsp;&nbsp;
                                        {t('workHour.label')} 8-00 - 18-00
                                    </li>
                                </ul>
                                <div className="ttm-social-links-wrapper list-inline">
                                    <ul className="social-icons">
                                        <li>
                                            <a className=" tooltip-bottom" data-tooltip="Facebook">
                                                <i className="fab fa-facebook-f"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a className=" tooltip-bottom" data-tooltip="Twitter">
                                                <i className="fab fa-twitter"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a className=" tooltip-bottom" data-tooltip="Linkedin">
                                                <i className="fab fa-linkedin-in"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ttm-topbar-wrapper end */}
            {/* ttm-header-wrap */}
            <div className="ttm-header-wrap">
                <div id="navbar" className="ttm-stickable-header clearfix">
                    <div className="site-header-menu">
                        <div className="container-xl">
                            {/* site-branding */}
                            <div className="site-branding">
                                <a
                                    className="home-link"
                                    href={process.env.PUBLIC_URL + "/"}
                                    title="Altech"
                                    rel="home"
                                >
                                    <img
                                        id="logo-img"
                                        className="img-fluid"
                                        alt="logo"
                                        src="images/logo.png"
                                    />
                                </a>
                            </div>
                            {/* site-branding end*/}
                            {/*site-navigation */}
                            <div className="site-navigation">
                                <div className="ttm-right-header"><LanguageSelector/></div>
                                <Menu/>
                                <div className="mobilemenu">
                                    <MobileMenu/>
                                </div>
                            </div>
                            {/*site-navigation end*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* ttm-header-wrap end*/}
        </header>
    );
}

export default Header;