import React from "react";
import {useTranslation} from 'react-i18next';

const ContactUS = () => {
    const {t, i18n} = useTranslation();

    return (
        <section className="ttm-row zero-padding-section clearfix" id="contactSection">
            <div className="container-xl">
                <div className="row no-gutters">
                    {/* row */}
                    <div className="col-lg-5">
                        <div className="spacing-9">
                            {/* section title */}
                            <div className="section-title with-desc clearfix">
                                <div className="title-header">
                                    <h5> {t('contactUS.mainText.label')} </h5>
                                    <h2 className="title"> {t('contactUS.secondMainText.label')} </h2>
                                </div>
                            </div>
                            {/* section title end */}
                            <div className="row">
                                <div className="col-12">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box style2 left-icon icon-align-top">
                                        <div className="featured-icon">
                                            {/* featured-icon */}
                                            <div
                                                className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                <i className="ti-location-pin"/>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                {/* featured title */}
                                                <h5> {t('contactUS.adressHeading.label')} </h5>
                                            </div>
                                            <div className="featured-desc">
                                                {/* featured desc */}
                                                <p> {t('contactUS.adressInfo.label')} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* separator */}
                            <div className="separator">
                                <div className="sep-line mt-4 mb-4"/>
                            </div>
                            {/* separator */}
                            <div className="row">
                                <div className="col-12">
                                    {/* featured-icon-box */}
                                    <div className="featured-icon-box style2 left-icon icon-align-top">
                                        <div className="featured-icon">
                                            {/* featured-icon */}
                                            <div
                                                className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                <i className="ti ti-email"/>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                {/* featured title */}
                                                <h5> {t('contactUS.emailHeading.label')} </h5>
                                            </div>
                                            <div className="featured-desc">
                                                {/* featured desc */}
                                                <p>contact@element63.app</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                <div className="ttm-bg-layer-inner"/>
                            </div>
                            {/* section title */}
                            <div className="section-title with-desc clearfix">
                                <div className="title-header">
                                    <h5> {t('contactUS.form.mainHeading.label')} </h5>
                                    <h2 className="title"> {t('contactUS.form.secondMainHeading.label')} </h2>
                                </div>
                            </div>
                            {/* section title end */}
                            <form
                                id="ttm-quote-form"
                                className="row ttm-quote-form clearfix"
                                method="post"
                                action="#"
                            >
                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control bg-white"
                                            placeholder={t('contactUS.form.name.label')}
                                            required="required"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <input
                                            name="phone"
                                            type="text"
                                            placeholder={t('contactUS.form.phNumber.label')}
                                            required="required"
                                            className="form-control bg-white"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <input
                                            name="address"
                                            type="text"
                                            placeholder={t('contactUS.form.email.label')}
                                            required="required"
                                            className="form-control bg-white"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6">
                                    <div className="form-group">
                                        <input
                                            name="subject"
                                            type="text"
                                            placeholder={t('contactUS.form.subject.label')}
                                            required="required"
                                            className="form-control bg-white"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-12">
                                    <div className="form-group">
                        <textarea
                            name="Massage"
                            rows={5}
                            placeholder={t('contactUS.form.message.label')}
                            required="required"
                            className="form-control bg-white"
                            defaultValue={""}
                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="text-left">
                                        <button
                                            type="submit"
                                            id="submit"
                                            className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor"
                                            value
                                        >
                                            {t('contactUS.form.button.label')}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* row end */}
            </div>
        </section>
    )
}


export default ContactUS;
