import React from "react";
import {
    MDBAnimation,
    MDBCarousel,
    MDBCarouselCaption,
    MDBCarouselInner,
    MDBCarouselItem,
    MDBContainer,
    MDBRow,
    MDBView
} from "mdbreact";
import {useTranslation} from 'react-i18next'
import car from '../../assets/images/Carousel/car-1.jpg';
import car1 from '../../assets/images/Carousel/car-2.jpg';

const CarouselPage = () => {
    const {t, i18n} = useTranslation();

    return (
        <MDBContainer fluid id="homeSection">
            <MDBRow>
                <MDBCarousel
                    activeItem={1}
                    length={2}
                    showControls={true}
                    showIndicators={false}
                    id="Home_banner"
                >
                    <MDBCarouselInner>
                        <MDBCarouselItem itemId="1">
                            <MDBView>
                                <img className="d-block w-100" src={car} alt="First slide"/>
                            </MDBView>
                            <MDBCarouselCaption>
                                <div className="container-xl">
                                    <div className="carousel-caption-inner banner1">
                                        <MDBAnimation className="slideInUp" count={1} delay="2s">
                                            <h2 className="ttm-textcolor-darkgrey flipInY"> {t('mainTextOne.label')} </h2>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="2.5s">
                                            <p className="text"> {t('secondTextOne.label')} </p>
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </MDBCarouselCaption>
                        </MDBCarouselItem>
                        <MDBCarouselItem itemId="2">
                            <MDBView>
                                <img className="d-block w-100" src={car1} alt="Second slide"/>
                            </MDBView>
                            <MDBCarouselCaption>
                                <div className="container-xl">
                                    <div className="carousel-caption-inner banner2">
                                        <MDBAnimation className="slideInUp" count={1} delay="5s">
                                            <h2 className="ttm-textcolor-darkgrey"> {t('mainTextTwo.label')} </h2>
                                        </MDBAnimation>
                                        <MDBAnimation className="slideInUp" count={1} delay="5.5s">
                                            <p className="text"> {t('secondTextTwo.label')} </p>
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </MDBCarouselCaption>
                        </MDBCarouselItem>
                    </MDBCarouselInner>
                </MDBCarousel>
            </MDBRow>
        </MDBContainer>
    );
}

export default CarouselPage;